import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


const NotFoundPage = () => (
  <Layout page="four-oh-four">
    <SEO title="404" />
    <h1>There’s nothing here.</h1>
  </Layout>
)

export default NotFoundPage
